<template>
  <section>
      <div class="content-header">
        <h2>What are some qualities of an academically tenacious student?</h2>
      </div>
      <div class="content-wrapper">
         <div class="content-wrapper">
            <!-- <h3>CAROUSEL</h3> -->
            <div class="carousel">
              <p>It can be helpful to think about academic resilience as encompassing, or scaffolded by, <em>academic tenacity</em>. </p>
              <p>Academic tenacity is a construct that can support students to persevere towards goals, experience belonging, engage in their learning, and explore new strategies to move towards their goals (Dweck et al., 2014). Many activities that can promote academic tenacity are things students are already doing or will need to do at some point in their degree (e.g., visit prof office hours; ask questions in class). Promoting engagement in activities aimed at improving academic tenacity can be embedded in courses in a variety of ways. (Reference: <a href="https://wellbeing.ubc.ca/fostering-academic-tenacity-through-promoting-participation" target="_blank">wellbeing.ubc.ca/fostering-academic-tenacity-through-promoting-participation</a>) </p>
              <p>Academically tenacious students share 10 qualities. They: </p>
              <hooper
                ref="carousel_01"
                class="mb-4"
                :wheelControl="false"
              >
                <slide>
                  <div class="slide">
                    <ol>
                      <li>Feel as though they belong in school, academically and socially</li>
                    </ol>
                  </div>
                </slide>

                <slide>
                  <div class="slide">
                    <ol start="2">
                      <li>See the relevance of education for achieving their personal future goals</li>
                    </ol>
                  </div>
                </slide>

                <slide>
                  <div class="slide">
                    <ol start="3">
                      <li>Value effort</li>
                    </ol>
                  </div>
                </slide>

                 <slide>
                  <div class="slide">
                    <ol start="4">
                      <li>Seek challenging tasks that will help them learn rather than stick with easy tasks that offer no opportunity for growth</li>
                    </ol>
                  </div>
                </slide>

                 <slide>
                  <div class="slide">
                    <ol start="5">
                      <li>View setbacks as an opportunity for learning rather than an indication of their low innate ability or worth</li>
                    </ol>
                  </div>
                </slide>

                <slide>
                  <div class="slide">
                    <ol start="6">
                      <li>Have a number of self-regulation strategies at their disposal to remain motivated and avoid distractions over the short and long haul</li>
                    </ol>
                  </div>
                </slide>
                <slide>
                  <div class="slide">
                    <ol start="7">
                      <li>Believe in their ability to learn and perform</li>
                    </ol>
                  </div>
                </slide>
                <slide>
                  <div class="slide">
                    <ol start="8">
                      <li>Enter the classroom with the goal of mastering the material, not outcompeting other students</li>
                    </ol>
                  </div>
                </slide>
                <slide>
                  <div class="slide">
                    <ol start="9">
                      <li>Have a sense of purpose, and feel that their learning will contribute value to the world beyond themselves</li>
                    </ol>
                  </div>
                </slide>
                <slide>
                  <div class="slide">
                    <ol start="10">
                      <li>Have positive, supportive relationships with teachers and peers</li>
                    </ol>
                  </div>
                </slide>

                <hooper-navigation slot="hooper-addons">
                  <img
                    slot="hooper-prev"
                    src="@/assets/img/_ui/svg/carousel-back.svg"
                    alt="previous"
                    srcset=""
                  >
                  <img
                    slot="hooper-next"
                    src="@/assets/img/_ui/svg/carousel-next.svg"
                    alt="next"
                    srcset=""
                  >
                </hooper-navigation>

                <hooper-pagination slot="hooper-addons"></hooper-pagination>
              </hooper>
            </div>
          </div>
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation,
  Pagination as HooperPagination
} from 'hooper'
import 'hooper/dist/hooper.css'

export default {
  name: '03',
  components: {
    // SidebarMenu,
    // Accordion,
    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination
  },
  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
